/**
 * Update date: 05-06-2023
 * Screen 2053.2
 */
import React, { useRef } from 'react';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import { useReceiveForward } from '..';
import useDictionary from 'hooks/useDictionary';
import PopupPG from '../ProductGroupPopup';
import { useApp } from 'app';
import PopupProductOrder from '../PopupProductOrder';

function TableList(props, dataGridRef) {
  const { t }: any = useDictionary({ programId: '2053.2' });
  const { themePro }: any = useApp();
  const { QtyFormat, AmountFormat, DoubleFormat } = useFormat();
  const { DateFormat } = useFormatTime();
  const context: any = useReceiveForward();
  const { dataSource, refetchGroup, viewPopup, setViewPopup } = context;

  const popupOrderRef: any = useRef();

  /**
   * Render action column grid
   *
   * @param {*} record
   * @return {*}
   */
  const renderAction = (record: any) => {
    return (
      <>
        <span
          onClick={event => {
            popupOrderRef.current?.onShowPopup(
              record?.data?.product_id || undefined,
            );
            event.stopPropagation();
          }}
          className="cell-link"
        >
          {t('Order')}
        </span>
      </>
    );
  };

  const columns: any = [
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'product_tp_nm',
          caption: t('Product Type'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_id',
          caption: t('Product ID'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'product_cd',
          caption: t('Product Code'),
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
        },
        {
          dataField: 'product_nm',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'product_maker_nm',
          caption: t('Maker'),
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'marketable_size',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'maker',
          caption: t('Maker'),
          visible: false,
        },
        {
          dataField: 'origin',
          caption: t('Origin'),
          visible: false,
        },
        {
          dataField: 'vendor_nm_default',
          caption: t('Default Vendor'),
          visible: false,
        },
        {
          dataField: 'largecategory',
          caption: t('Large Category'),
          visible: false,
        },
        {
          dataField: 'mediumcategory',
          caption: t('Medium Category'),
          visible: false,
        },
        {
          dataField: 'smallcategory',
          caption: t('Small Category'),
          visible: false,
        },
        {
          dataField: 'subcategory',
          caption: t('Sub Category'),
          visible: false,
        },
      ],
    },
    {
      caption: t('Receive'),
      alignment: 'center',
      columns: [
        {
          dataField: 'last_receive_vendor_nm',
          caption: t('Final Vendor'),
          showWhenGrouped: true,
        },
        {
          dataField: 'min_receive_date',
          caption: t('First Receive Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'max_receive_date',
          caption: t('Last Receive Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_ea',
          caption: t('Period Receive ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_amount',
          caption: t('Period Receive Amount'),
          format: AmountFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'receive_count',
          caption: t('Period Receive Count'),
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'min_forward_date',
          caption: t('First Forward Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: themePro ? 'left' : 'center',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'max_forward_date',
          caption: t('Last Forward Date'),
          format: DateFormat,
          dataType: 'date',
          alignment: 'left',
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forward_ea',
          caption: t('Period Forward Ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'forward_amount',
          caption: t('Period Forward Amount'),
          format: AmountFormat,
          showWhenGrouped: true,
          visible: false,
        },
        {
          dataField: 'forward_count',
          caption: t('Period Forward Count'),
          format: QtyFormat,
          showWhenGrouped: true,
          visible: false,
        },
      ],
    },
    {
      caption: t('Stock'),
      alignment: 'center',
      columns: [
        {
          dataField: 'stock_ea',
          caption: t('Stock Ea'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'forward_7days',
          caption: t('Last 7days sale'),
          format: QtyFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'receive_ratio_stock',
          caption: t('Receive Stock Ratio'),
          format: DoubleFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'stock_week_index',
          caption: t('Stock Week Index'),
          format: DoubleFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Action'),
      alignment: 'center',
      fixedPosition: 'right',
      fixed: true,
      width: 80,
      cellRender: renderAction,
    },
  ];

  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };
  const exportFileName =
    'SMDC_2053.2 Product Receive Forward Inquiry' + moment().format('YYYYMMDD');

  /**
   * on show popup
   *
   */
  const showPopup = () => {
    refetchGroup({
      data: {
        productGroupNm: '',
        sortBy: true,
      },
    });
    setViewPopup(true);
  };

  return (
    <>
      <DataGridFull
        columns={columns}
        ref={dataGridRef}
        dataSource={dataSource}
        fixedLeft={0}
        options={{
          key: 'product_id',
          columnAutoWidth: true,
          groupPanel: {
            visible: true,
          },
          scrolling: {
            columnRenderingMode: 'virtual',
          },
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: '',
                  onClick: () => exportExcel(),
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'selectall',
                  text: t('Product Group'),
                  disabled: '',
                  onClick: () => showPopup(),
                },
              },
            );
          },
          selection: {
            mode: 'multiple',
            selectAllMode: 'allPages',
            showCheckBoxesMode: 'onClick',
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              // {
              //   name: '',
              //   column: '',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              // },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              // {
              //   column: '',
              //   valueFormat: AmountFormat,
              //   displayFormat: '{0}',
              //   summaryType: 'sum',
              //   alignByColumn: true,
              //   showInGroupFooter: false,
              // },
            ],
          },
        }}
      />
      {PopupPG && (
        <PopupPG
          visible={viewPopup}
          onSubmit={() => {}}
          onHiding={() => {
            setViewPopup(false);
            dataGridRef?.current?.instance?.deselectAll();
          }}
        />
      )}
      <PopupProductOrder ref={popupOrderRef} />
    </>
  );
}

export default React.forwardRef(TableList);
