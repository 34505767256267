/**
 * Update date: 09-06-2023
 * Layout component
 */
import useAxios from 'axios-hooks';
import React, { useContext, useEffect, useState } from 'react';
import tokenService from 'services/auth.service';
import LoadPanel from '../LoadPanel';
import { LANG_DEFAULT } from 'constant';
import { useApp } from 'app';
import { loadMessages } from 'devextreme/localization';

/**
 * create context
 */

export const LayoutWebviewMobileContext = React.createContext({});
LayoutWebviewMobileContext.displayName = 'LayoutWebviewMobileContext';

export const useLayoutWebviewMobile = () => {
  const context = useContext(LayoutWebviewMobileContext);
  if (!context) return;
  return context;
};

const LayoutWebviewMobile = props => {
  const { setVoca, setFirstRender }: any = useApp();
  const { children } = props;
  const [validToken, setvalidToken] = useState(false);

  const [{ loading }, fetchData] = useAxios(
    {
      url: '/core/multi-language',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const getDictionary = async (lang: string) => {
    const res = await fetchData({
      data: {
        type: 'ui',
        languageCode: lang,
      },
    });
    if (res?.data?.status === '200') {
      const messages = [...res?.data?.data].filter(
        o => o?.languageCode === lang,
      );
      let l: any = {};
      messages?.forEach((o, i) => {
        if (o?.translation || o?.base) {
          l[`${o?.id}-${o?.base}`] = o?.translation || o?.autoTranslation;
        }
      });
      loadMessages({
        [lang]: l,
      });
      setVoca(l);
      setFirstRender(false);
      setTimeout(() => setvalidToken(true), 200);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search);
    const tokenFromUrl = searchParams?.get('token') || '';
    const langFromUrl = searchParams?.get('lang') || LANG_DEFAULT;
    if (tokenFromUrl && tokenFromUrl?.trim()) {
      tokenService.updateLocalAccessToken(tokenFromUrl);
      setTimeout(() => getDictionary(langFromUrl), 100);
    } else {
      tokenService.updateLocalAccessToken('');
    }
    return () => {
      tokenService.updateLocalAccessToken('');
    };
  }, []);

  const value: any = {};

  return (
    <LayoutWebviewMobileContext.Provider value={value}>
      {validToken ? (
        <div className="webview-mobile-layout">{children}</div>
      ) : (
        <div></div>
      )}
      <LoadPanel visible={loading} />
    </LayoutWebviewMobileContext.Provider>
  );
};

export default LayoutWebviewMobile;
