/**
 * Update date: 23-05-2023
 * Screen 2023.2
 */
import { NotFoundPage } from 'app/components/NotFoundPage';
import useAxios from 'axios-hooks';
import LoadPanel from 'app/components/LoadPanel';
import ArrayStore from 'devextreme/data/array_store';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ListOrder from './ListOrder';
const defaultParams = {
  fromDate: new Date(),
  endDate: new Date(),
  vendorIds: [],
  status: '',
  productIds: [],
  largeCategoryCodes: [],
  mediumCategoryCodes: [],
  smallCategoryCodes: [],
  subCategoryCodes: [],
  productGroupIds: [],
  userIds: [],
};
/**
 * create context
 */
const OrderInquiryContext = createContext({
  params: {},
  setParams: () => {},
  refetchReceive: () => {},
  listData: [],
  modeView: {},
  setModeView: () => {},
});

OrderInquiryContext.displayName = 'OrderInquiryContext';

export const useOrderInquiry = () => {
  const context = useContext(OrderInquiryContext);
  return context;
};

function OrderInquiry() {
  const dataGridRef: any = useRef(null);
  const [params, setParams] = useState(defaultParams);
  const [modeView, setModeView] = useState({ type: 'list' });
  const [listData, setListData] = useState<any>([]);
  const store: any = new ArrayStore({
    data: listData,
    key: 'lineId',
  });
  const [{ data, loading: loadingData }, refetchReceive] = useAxios(
    {
      url: '/warehouse/order-registration/details',
      method: 'POST',
    },
    { manual: true },
  );
  /**
   * render content
   *
   * @return {*}
   */
  const renderContent = () => {
    switch (modeView?.type) {
      case 'list':
        return <ListOrder ref={dataGridRef} />;
      default:
        return <NotFoundPage />;
    }
  };
  /**
   * on deselect all
   *
   */
  const deselectAll = () => {
    dataGridRef?.current?.instance?.deselectAll();
  };
  /**
   * on add
   *
   * @param {*} data
   */
  const onAdd = data => {
    setListData(preState => {
      preState.splice(0, 0, data);
      return preState;
    });
    dataGridRef?.current?.instance?.refresh();
  };
  /**
   * on update
   *
   * @param {*} data
   */
  const onUpdate = data => {
    store
      .update(data?.lineId, data)
      .done(() => {})
      .fail(() => {});
  };

  const value: any = {
    params,
    setParams,
    refetchReceive,
    store,
    deselectAll,
    modeView,
    setModeView,
    onAdd,
    onUpdate,
  };
  /**
   * fetch data
   */
  useEffect(() => {
    if (data?.data) {
      setListData(data?.data);
    }
  }, [data]);
  const loadingAll = loadingData;
  return (
    <OrderInquiryContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingAll} />
        {renderContent()}
      </React.Suspense>
    </OrderInquiryContext.Provider>
  );
}

export default OrderInquiry;
