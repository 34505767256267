/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import PopupProductDetail from 'app/components/PopupCommon/PopupProductDetail';
import { Button, Popup, ScrollView } from 'devextreme-react';
import { formatNumber } from 'devextreme/localization';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './styles.scss';
interface IPopupProductClick {
  onOk?: (data?: any) => void;
}
const PopupProductClick = ({ onOk }: IPopupProductClick, ref) => {
  const { t } = useDictionary({ programId: 'Home-ROP' });
  const productDetailRef: any = useRef(null);
  const { QtyFormat } = useFormat();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [productData, setProductData] = useState<any>([]);
  const [positionOf, setPositionOf] = useState<any>(null);

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  const onOpen = async (data, point: any = null) => {
    if (!data) return;
    const minus = 215;
    setPositionOf({
      startX:
        point?.startX - minus > 400
          ? point?.startX - minus
          : point?.startX + minus,
      startY: point?.startY - 200,
    });
    setProductData(data);
    setShowPopupConfirm(true);
  };

  const onClickNo = () => {
    setShowPopupConfirm(false);
    setProductData([]);
  };

  const content = () => {
    return (
      <div>
        <div className="modal-popup-header" style={{ padding: '0 15px' }}>
          <span className="title-page">{t('Product Selected')}</span>
          <div>
            <Button
              className="btn-small-size-popup"
              stylingMode="contained"
              text={t('Close')}
              icon="close"
              onClick={onClickNo}
            />
          </div>
        </div>
        <ScrollView width="100%" height="100%">
          <div className="popup-click-rop">
            {productData?.map((item, index) => (
              <div
                key={index}
                className={`item-content ${
                  index !== 0 ? 'item-content-border' : ''
                }`}
              >
                <div className="product-image">
                  <img
                    src={item?.productImg || ''}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    alt=""
                  />
                </div>
                <div className="product-info">
                  <div className="line-height">
                    <span className="label-item">{t('Product Name')}: </span>
                    <span className="value-item">{item.productNm}</span>
                  </div>
                  <div className="stock-and-btn">
                    <div>
                      <div className="line-height">
                        <span className="label-item">{t('Sale Qty')}: </span>
                        <span className="value-item">
                          {formatNumber(item.saleQty, QtyFormat)}
                        </span>
                      </div>
                      <div className="line-height">
                        <span className="label-item">
                          {t('Stock Of The Week')}:{' '}
                        </span>
                        <span className="value-item">{item.stock}</span>
                      </div>
                    </div>
                    <div>
                      <Button
                        className="btn-small-size-popup"
                        stylingMode="contained"
                        type="default"
                        text={t('Detail')}
                        onClick={() =>
                          productDetailRef.current?.onShowPopup(item.productId)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollView>
        <PopupProductDetail ref={productDetailRef} />
      </div>
    );
  };

  return (
    <Popup
      className="popup-custom-css"
      visible={showPopupConfirm}
      contentRender={content}
      height={isMobile ? '80vh' : '400'}
      width={isMobile ? '98%' : '400'}
      position={{
        of: window,
        my: 'center',
        at: 'left',
        collision: 'fit',
        offset: { x: positionOf?.startX || 0, y: positionOf?.startY || 0 },
      }}
    />
  );
};

export default memo(forwardRef(PopupProductClick));
