/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from 'devextreme-react/chart';

import { formatNumber } from 'devextreme/localization';
import useFormat from 'hooks/useFormat';

const defaultValue = [
  {
    category: '',
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
    value5: 0,
  },
];

const seriesSetting = [
  { valueField: 'value1', color: '#8B0000', range: '0->1' },
  { valueField: 'value2', color: '#A52A2A', range: '1->2' },
  { valueField: 'value3', color: '#FF8C00', range: '2->3' },
  { valueField: 'value4', color: '#FFA500', range: '3->4' },
  { valueField: 'value5', color: '#FFD700', range: '4->5' },
];

const ChartCountQuantityStock = ({ t, data }) => {
  const { QtyFormat } = useFormat();
  const barWidth = 35;
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#fff',
        paddingLeft: 75,
        paddingRight: 10,
        paddingBottom: 20,
      }}
    >
      <Chart
        id="stacked-bar-chart"
        dataSource={data?.data || defaultValue}
        rotated={true}
        size={{
          height: 150,
        }}
      >
        <ArgumentAxis />
        <ValueAxis
          title={t(
            'Sales product {0}% (Sales product SKU count {1} / Total product {2})',
          )
            .replace('{0}', data?.percentProduct)
            .replace('{1}', formatNumber(data?.totalChartProduct, QtyFormat))
            .replace('{2}', formatNumber(data?.originTotal, QtyFormat))}
          visualRange={{
            startValue: 0,
            endValue: data?.totalProduct,
          }}
          minorTick={{ visible: false }}
          tickInterval={data?.totalProduct <= 20 ? 1 : ''}
          label={{ format: value => formatNumber(value, QtyFormat) }}
        />
        {seriesSetting.map(v => (
          <Series
            key={v.valueField}
            valueField={v.valueField}
            argumentField="category"
            name={v.valueField}
            type="stackedBar"
            color={v.color}
            barWidth={barWidth}
          />
        ))}
        <Tooltip
          enabled={true}
          shared={true}
          customizeTooltip={pointInfo => {
            const series = seriesSetting.find(
              v => v.valueField === pointInfo?.seriesName,
            );
            if (series) {
              return {
                text: `${t('Stock of the week')} ${
                  series.range
                }: ${formatNumber(pointInfo?.value || 0, QtyFormat)} ${t(
                  'products',
                )}`,
              };
            }
            return {
              text: `${formatNumber(pointInfo?.value || 0, QtyFormat)}`,
            };
          }}
        />
        <Legend visible={false} />
      </Chart>
    </div>
  );
};

export default ChartCountQuantityStock;
