/**
 * Update date: 2024-11-29
 * Screen Home Page
 */
import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import { CheckBox, RadioGroup } from 'devextreme-react';
import useDictionary from 'hooks/useDictionary';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import { notification } from 'utils/notification';
import ChartCountQuantityStock from './ChartCountQuantityStock';
import PopupProductSelectArea from './PopupProductSelectArea';
import './styles.scss';
import PopupProductClick from './PopupProductClick';

const TabReorderPoint = () => {
  const { t } = useDictionary({ programId: 'Home-ROP' });
  const productAreaRef: any = useRef(null);
  const productClickRef: any = useRef(null);
  const chartContainerRef = useRef<any>(null);
  const selectDivRef = useRef<any>(null);
  const startRef = useRef<any>(null);
  const [chartWrapper, setChartWrapper] = useState<any>(null);
  const [chartData, setChartData] = useState<any>([]);
  const [originData, setOriginData] = useState<any>([]);
  const [chartTotal, setChartTotal] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    type: '1',
    exclude0: true,
    exclude1: true,
  });

  const [{ loading }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  useEffect(() => {
    getDataChart();
  }, [formData]);

  const getDataChart = async () => {
    const excludeType: any = [];
    if (formData?.exclude0) {
      excludeType.push('0');
    }
    if (formData?.exclude1) {
      excludeType.push('1');
    }
    const res = await refetchData({
      url: '/account/reorder-point-rop',
      method: 'POST',
      data: {
        type: formData?.type || '1',
        excludeType,
        timezone: -(new Date().getTimezoneOffset() / 60),
      },
    });
    if (res?.data?.status === '200') {
      const { chartData = [], totalProduct = 0 } = res?.data?.data || {};
      const chartProductTotal = {
        category: '',
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        value5: 0,
      };
      const dataRes: any = [['x', 'y', { role: 'style' }, { role: 'hidden' }]];
      chartData?.forEach(o => {
        dataRes.push([
          o.stock,
          o.saleQty,
          'point { fill-color: #7F56D9; }',
          o.productId,
        ]);
        if (o.stock <= 1) {
          chartProductTotal.value1 += 1;
        } else if (o.stock > 1 && o.stock <= 2) {
          chartProductTotal.value2 += 1;
        } else if (o.stock > 2 && o.stock <= 3) {
          chartProductTotal.value3 += 1;
        } else if (o.stock > 3 && o.stock <= 4) {
          chartProductTotal.value4 += 1;
        } else if (o.stock > 4 && o.stock <= 5) {
          chartProductTotal.value5 += 1;
        }
      });
      const valueTotal =
        chartProductTotal.value1 +
          chartProductTotal.value2 +
          chartProductTotal.value3 +
          chartProductTotal.value4 +
          chartProductTotal.value5 || 1;
      setOriginData(chartData);
      setChartData(dataRes);
      setChartTotal({
        originTotal: totalProduct,
        totalChartProduct: valueTotal,
        percentProduct:
          Math.round((valueTotal * 100 * 1000) / totalProduct) / 1000,
        totalProduct:
          totalProduct / valueTotal > 4 ? valueTotal * 3 : totalProduct,
        data: chartData?.length ? [chartProductTotal] : [],
      });
    } else {
      notification({ res });
    }
  };

  const getDataPx = (x1, y1, x2, y2) => {
    const x3 = Math.min(x1, x2);
    const y3 = Math.min(y1, y2);
    const x4 = Math.max(x1, x2);
    const y4 = Math.max(y1, y2);
    const left = x3;
    const top = y3;
    const width = x4 - x3;
    const height = y4 - y3;
    return { left, top, width, height };
  };

  const reCalc = (x1, y1, x2, y2) => {
    const { left, top, width, height } = getDataPx(x1, y1, x2, y2);
    const selectDiv = selectDivRef.current;
    selectDiv.style.left = `${left}px`;
    selectDiv.style.top = `${top}px`;
    selectDiv.style.width = `${width}px`;
    selectDiv.style.height = `${height}px`;
    selectDiv.style.display = 'block';
    selectDiv.style.visibility = 'unset';
  };

  const handleMouseDown = e => {
    e?.preventDefault?.();
    const { left, top } = chartContainerRef?.current?.getBoundingClientRect();
    startRef.current = {
      startX: e.pageX - left,
      startY: e.pageY - top,
      endX: null,
      endY: null,
      pageX: e.pageX,
    };
  };

  const handleMouseMove = e => {
    e?.preventDefault?.();
    const { startX = null, startY = null } = startRef.current || {};
    if (startX == null || startY == null) {
      return;
    }
    const selectDiv = selectDivRef.current;
    if (!selectDiv.style.display) return;
    const { left, top } = chartContainerRef?.current?.getBoundingClientRect();
    const endX = e.pageX - left;
    const endY = e.pageY - top;
    startRef.current = { startX, startY, endX, endY };
    reCalc(startX, startY, endX, endY);
  };

  const handleMouseUp = e => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (!chartWrapper) return;
    let isClick = false;
    const selectDiv = selectDivRef.current;
    selectDiv.style.display = 'none';
    selectDiv.style.visibility = 'hidden';
    const chart = chartWrapper.getChart();
    const chartLayout = chart.getChartLayoutInterface();
    const { startX, startY, endX, endY, pageX } = startRef.current || {};
    let newStartX = startX;
    let newStartY = startY;
    let newEndX = endX;
    let newEndY = endY;
    if (!endX || !endY) {
      newStartX = startX - 15;
      newStartY = startY - 15;
      newEndX = startX + 15;
      newEndY = startY + 15;
      isClick = true;
    }
    const { left, top, width, height } = getDataPx(
      newStartX,
      newStartY,
      newEndX,
      newEndY,
    );
    const xMin = Math.min(left, left + width);
    const xMax = Math.max(left, left + width);
    const yMin = Math.min(top, top + height);
    const yMax = Math.max(top, top + height);
    const selection: any = [];
    const newData = chartData.map((row, index) => {
      if (index === 0) return row; // Header row
      const newRow = [...row];
      const point = chartLayout.getBoundingBox(`point#0#${index - 1}`);
      if (
        point &&
        point.left >= xMin &&
        point.left + point.width <= xMax &&
        point.top >= yMin &&
        point.top + point.height <= yMax
      ) {
        selection.push(row?.[3]);
        newRow[2] = 'point { fill-color: #ff0000; }';
        return newRow;
      }
      newRow[2] = 'point { fill-color: #7F56D9; }';
      return newRow;
    });
    startRef.current = null;
    setChartData(newData);
    if (!selection?.length) return;
    if (isClick) {
      const clickList = originData.filter(o => selection.includes(o.productId));
      productClickRef?.current?.onOpen?.(clickList, {
        startX: pageX,
        startY: startY,
      });
    } else {
      productAreaRef?.current?.onOpen?.({
        type: formData.type,
        productIds: selection,
        timezone: -(new Date().getTimezoneOffset() / 60),
      });
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: 5 }}>
      <LoadPanel visible={loading} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: 10,
          gap: 80,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RadioGroup
            layout={'horizontal'}
            items={[
              { text: t('Yesterday'), value: '1' },
              { text: t('Last week'), value: '2' },
              { text: t('Last month'), value: '3' },
            ]}
            defaultValue={formData.type}
            valueExpr="value"
            displayExpr="text"
            onValueChanged={e => setFormData({ ...formData, type: e?.value })}
          />
        </div>
        <div>
          <div style={{ marginBottom: 5 }}>
            <CheckBox
              text={t(
                'Exclude products with order history from the last 7 days to today',
              )}
              defaultValue={formData.exclude0}
              onValueChanged={e =>
                setFormData({ ...formData, exclude0: e?.value })
              }
            />
          </div>
          <div>
            <CheckBox
              text={t('Exclude consignment delivery products')}
              defaultValue={formData.exclude1}
              onValueChanged={e =>
                setFormData({ ...formData, exclude1: e?.value })
              }
            />
          </div>
        </div>
      </div>
      {chartData.length <= 1 ? (
        <p style={{ textAlign: 'center', marginTop: 100, height: 500 }}>
          {t('No data available to display')}
        </p>
      ) : (
        <div
          ref={chartContainerRef}
          style={{
            position: 'relative',
            width: '100%',
            height: '500px',
            margin: 'auto',
            backgroundColor: '#fff',
            borderRadius: 5,
            marginBottom: 10,
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <div
            ref={selectDivRef}
            style={{
              position: 'absolute',
              border: '1px dashed #DC6803',
              backgroundColor: 'rgba(51, 102, 204, 0.1)',
              display: 'none',
              visibility: 'hidden',
              zIndex: 1000,
            }}
          ></div>
          <Chart
            chartType="ScatterChart"
            width="100%"
            height="100%"
            data={chartData}
            className="scatter-chart-rop"
            options={{
              chartArea: {
                height: '100%',
                width: '100%',
                top: 32,
                left: 80,
                right: 20,
                bottom: 40,
              },
              hAxis: {
                title: t('Stock of the week'),
                gridlines: {
                  count: 0,
                  color: '#eff1f6',
                },
                ticks: [0, 1, 2, 3, 4, 5],
                titleTextStyle: {
                  color: 'rgb(118, 118, 118)',
                  italic: false,
                  bold: false,
                },
                baselineColor: 'rgb(118, 118, 118)',
              },
              vAxis: {
                title: t('Sales qty'),
                gridlines: {
                  color: '#eff1f6',
                },
                titleTextStyle: {
                  color: 'rgb(118, 118, 118)',
                  italic: false,
                  bold: false,
                },
                baselineColor: 'rgb(118, 118, 118)',
              },
              tooltip: { trigger: 'none' },
              legend: 'none',
              selectionMode: 'multiple',
            }}
            chartEvents={[
              {
                eventName: 'ready',
                callback: ({ chartWrapper }) => {
                  setChartWrapper(chartWrapper);
                },
              },
            ]}
          />
        </div>
      )}

      {chartData.length > 1 ? (
        <ChartCountQuantityStock t={t} data={chartTotal} />
      ) : (
        <></>
      )}

      <PopupProductSelectArea ref={productAreaRef} />
      <PopupProductClick ref={productClickRef} />
    </div>
  );
};

export default TabReorderPoint;
