import { DataGridFull } from 'app/components/DataGrid';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import moment from 'moment-timezone';
import { useRef } from 'react';
import { useStoreContext } from '..';

/**
 * Data Grid component
 *
 * @return {*}
 */
function SalesStatisticsTable() {
  const dataGridRef: any = useRef(null);
  const subGridRef: any = useRef(null);
  const context: any = useStoreContext();
  const {
    formData,
    formStore,
    refetchDataPrd,
    onSearch,
    salesData,
    prdData,
    setTapName,
  } = context;
  const store: any = new ArrayStore({
    data: salesData || [],
    key: ['storeid', 'receivablecd', 'producttpid', 'supplyunitpricegroup'],
  });
  const { QtyFormat, AmountFormat, SingleFormat } = useFormat();

  const { t }: any = useDictionary({
    programId: '2087.5',
  });

  // Render column of grid store
  const columns: any = [
    {
      caption: t('Store'),
      alignment: 'center',
      columns: [
        {
          dataField: 'storeid',
          caption: t('Store No.'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storenm',
          caption: t('Store'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'receivablecd',
          caption: t('Receivable Code'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'receivablenm',
          caption: t('Receivable Group'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttpid',
          caption: t('Product Type Id'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttype',
          caption: t('Product Type'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyunitpricegroup',
          caption: t('Supply Unit Price Group Id'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyunitpricegroupnm',
          caption: t('Supply Unit Price Group'),
          allowEditing: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'salesea',
          caption: t('Sales EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'sellsamount',
          caption: t('Sells Amount'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salesamount',
          caption: t('Sales Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salessupplyamount',
          caption: t('Sales Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'salesvat',
          caption: t('Sales Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'salescostamount',
          caption: t('Sales Cost Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitrate',
          caption: t('Profit Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'costrate',
          caption: t('Cost Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'fdea',
          caption: t('Forward EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'fdamount',
          caption: t('Forward Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'fdsupplyamount',
          caption: t('Forward Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'fdvat',
          caption: t('Forward Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'fdcostamount',
          caption: t('Forward Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Return'),
      alignment: 'center',
      columns: [
        {
          dataField: 'rdea',
          caption: t('Return EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'rdamount',
          caption: t('Return Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'rdsupplyamount',
          caption: t('Return Supply Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'rdvat',
          caption: t('Return Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'rdcostamount',
          caption: t('Return Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
      ],
    },
  ];

  // Render column of grid product
  const subColumns: any = [
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'storeid',
          caption: t('Store No.'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'storenm',
          caption: t('Store'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productid',
          caption: t('Product ID'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productcd',
          caption: t('Product Code'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'productnm',
          caption: t('Product Name'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'marketablesize',
          caption: t('Marketable Size'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'receivablecd',
          caption: t('Receivable Code'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'receivablenm',
          caption: t('Receivable Group'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttp',
          caption: t('Product Type Id'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'producttpnm',
          caption: t('Product Type'),
          allowEditing: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyunitpricegroup',
          caption: t('Supply Unit Price Group Id'),
          allowEditing: false,
          visible: false,
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyunitpricegroupnm',
          caption: t('Supply Unit Price'),
          allowEditing: false,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Summary'),
      alignment: 'center',
      columns: [
        {
          dataField: 'salesea',
          caption: t('Sales EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'sellsamount',
          caption: t('Sells Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salesamount',
          caption: t('Sales Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'salessupply',
          caption: t('Sales Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'salesvat',
          caption: t('Sales Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'salescost',
          caption: t('Sales Cost Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'profitrate',
          caption: t('Profit Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'costrate',
          caption: t('Cost Rate'),
          allowEditing: false,
          format: SingleFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Forward'),
      alignment: 'center',
      columns: [
        {
          dataField: 'forwardea',
          caption: t('Forward EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'forwardamount',
          caption: t('Forward Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'forwardsupply',
          caption: t('Forward Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          allowEditing: false,
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'forwardvat',
          caption: t('Forward Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'forwardcost',
          caption: t('Forward Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Return'),
      alignment: 'center',
      columns: [
        {
          dataField: 'returnea',
          caption: t('Return EA'),
          allowEditing: false,
          format: QtyFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'returnamount',
          caption: t('Return Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
        {
          dataField: 'returnsupply',
          caption: t('Return Supply Amount'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'returnvat',
          caption: t('Return Vat'),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
          // visible: false,
        },
        {
          dataField: 'returncost',
          caption: t('Return Cost Amount '),
          allowEditing: false,
          format: AmountFormat,
          dataType: 'number',
          className: 'red-font',
          showWhenGrouped: true,
        },
      ],
    },
  ];

  /**
   * Export excel store grid
   *
   */
  const exportExcelStore = () => {
    dataGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * Export excel product grid
   *
   */
  const exportExcelProduct = () => {
    subGridRef?.current?.instance?.exportToExcel();
  };

  /**
   * on change tab panel
   *
   * @param {*} title
   */
  const onChangeTabPanel = (title: any) => {
    setTapName(t(title));
    const subParams = {
      store: formStore.store?.map(o => o?.storeNo),
      storeGroup: formStore.storeGroup?.map(o => o?.storeGroupId),
      unitPrice: formStore.unitPrice?.map(o => o?.code),
      productTypes: formStore.productTypes?.map(o => o?.code),
      receivableCd: formStore.receivableCd.map((o: any) => o.code),
      fromDate: formData?.fromDate
        ? moment(formData?.fromDate).format('YYYY-MM-DD')
        : null,
      endDate: formData?.endDate
        ? moment(formData?.endDate).format('YYYY-MM-DD')
        : null,
    };
    if (title === t('Product')) {
      refetchDataPrd({
        data: subParams,
      });
    } else {
      onSearch(subParams);
    }
  };

  return (
    <>
      <TabPanel
        defaultSelectedIndex={0}
        swipeEnabled={false}
        onSelectionChanged={(e: any) => {
          onChangeTabPanel(t(e?.addedItems[0].title));
        }}
        className="theme-premiun-dx-multiview-wrapper-0"
      >
        <TabItem title={t('Store')}>
          <DataGridFull
            storageKeyInquiry="store"
            columns={columns}
            ref={dataGridRef}
            dataSource={store}
            options={{
              key: ['storeid', 'supplyunitpricegroup', 'producttpid'],
              groupPanel: {
                visible: true,
              },
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: '',
                      onClick: exportExcelStore,
                    },
                  },
                );
              },
              export: {
                fileName: `SMDC_2087.5_Sales Statistics by Store_${moment().format(
                  'YYYYMMDD',
                )}`,
              },
              summary: {
                totalItems: [
                  {
                    column: 'fdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'fdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'sellsamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salessupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'rdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'costrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'fdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'fdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'fdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'sellsamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salessupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdsupplyamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'rdcostamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'costrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                ],
              },
            }}
          />
        </TabItem>
        <TabItem title={t('Product')}>
          <DataGridFull
            storageKeyInquiry="product"
            columns={subColumns}
            ref={subGridRef}
            dataSource={prdData}
            options={{
              key: '',
              groupPanel: {
                visible: true,
              },
              columnAutoWidth: true,
              onToolbarPreparing: (e: any) => {
                e.toolbarOptions.items.unshift(
                  {
                    location: 'before',
                    template: 'totalCount',
                  },
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: 'xlsxfile',
                      text: t('Export Excel'),
                      disabled: '',
                      onClick: exportExcelProduct,
                    },
                  },
                );
              },
              export: {
                fileName: `SMDC_2087.5_Sales Statistics by Product_${moment().format(
                  'YYYYMMDD',
                )}`,
              },
              summary: {
                totalItems: [
                  {
                    column: 'forwardea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'forwardcost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'sellsamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salessupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'salescost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'profitrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'costrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returnvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                  {
                    column: 'returncost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                  },
                ],
                groupItems: [
                  {
                    summaryType: 'count',
                    displayFormat: '{0}',
                  },
                  {
                    column: 'forwardea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    alignByColumn: true,
                    showInGroupFooter: false,
                  },
                  {
                    column: 'forwardamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'forwardcost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'sellsamount',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salessupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salesvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'salescost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnea',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnamount',
                    valueFormat: QtyFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnsupply',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returnvat',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'returncost',
                    valueFormat: AmountFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'profitrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                  {
                    column: 'costrate',
                    valueFormat: SingleFormat,
                    displayFormat: '{0}',
                    dataType: 'number',
                    summaryType: 'sum',
                    showInGroupFooter: false,
                    alignByColumn: true,
                  },
                ],
              },
            }}
          />
        </TabItem>
      </TabPanel>
    </>
  );
}

export default SalesStatisticsTable;
