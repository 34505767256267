import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import { useEffect, useState, useRef } from 'react';
import { notification } from 'utils/notification';
import { useProductDetail } from '../..';
import { DataGridFull } from 'app/components/DataGrid';
import useFormatTime from 'hooks/useFormatTime';
import { dateFormatStr } from 'utils/format';
import Form from 'devextreme-react/form';
import PopupConfirm from 'app/components/PopupCommon/PopupConfirm';
import { Button } from 'devextreme-react';
import ChangeProductMinOrder from './ChangeProductMinOrder';
import { formatNumber } from 'devextreme/localization';
import moment from 'moment';
import ChangeProductSafetyStock from './ChangeProductSafetyStock';
import './style-bottom.scss';

const columnEdit2053_2Order = ['qty', 'purchase_unit_price'];

const ViewBottom = () => {
  const { t } = useDictionary({});
  const refForm = useRef<any>(null);
  const dataGridRef: any = useRef(null);
  const { productId, visible }: any = useProductDetail();
  const [dataSource, setDataSource] = useState<any>([]);
  const [formData, setFormData] = useState<any>({
    orderDate: new Date(),
    orderDueDate: moment().add(1, 'days'),
  });
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [updateMinOrderPopup, setUpdateMinOrderPopup] = useState<any>({
    isShow: false,
    data: null,
  });
  const [updateSafetyStockPopup, setUpdateSafetyStockPopup] = useState<any>({
    isShow: false,
    data: null,
  });

  const { QtyFormat, DoubleFormat }: any = useFormat();
  const { DateFormat } = useFormatTime();

  const [{ loading }, refetchData] = useAxios(
    {
      url: '/warehouse/order-registration/get-product-option-to-order',
      method: 'POST',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const [{ loading: loadingUpdate }, refetchCreate] = useAxios(
    {
      url: '/warehouse/order-registration/create-order/from-popup',
      method: 'PUT',
    },
    { manual: true, autoCancel: true, useCache: false },
  );

  const getOptionOrder = async (isUpdate = false) => {
    if (!formData?.orderDueDate) return;
    const res = await refetchData({
      data: {
        productId: productId,
        deliverDueDate: dateFormatStr(formData?.orderDueDate),
      },
    });
    if (res?.data?.status === '200') {
      const resData = res?.data?.data || [];
      if (!isUpdate) {
        setDataSource(resData);
      } else {
        setDataSource(prev => {
          return prev.map(o => {
            const finData = resData.find(
              f => f?.option_id == o?.option_id && f?.vendor_id == o?.vendor_id,
            );
            o.purchase_unit_price = finData
              ? finData?.purchase_unit_price || 0
              : 0;
            return o;
          });
        });
      }
    } else {
      notification({ res });
    }
  };

  useEffect(() => {
    if (visible && productId) {
      getOptionOrder();
    } else if (!visible) {
      setFormData({
        orderDate: new Date(),
        orderDueDate: moment().add(1, 'days'),
      });
      setDataSource([]);
    }
  }, [visible, productId]);

  const onValidateOrder = () => {
    const isValid = refForm?.current?.instance?.validate()?.isValid;
    if (!isValid) return;

    const selectedData =
      dataGridRef?.current?.instance?.getSelectedRowsData?.() || [];
    if (!selectedData?.length) {
      return notification({
        message: t('You must be select at least one record. Please try again!'),
        type: 'error',
      });
    }
    if (selectedData.filter(o => !o?.qty)?.length) {
      return notification({
        message: t('Please enter the quantity for the selected record.'),
        type: 'error',
      });
    }
    setShowPopupConfirm(true);
  };

  const onOrder = async () => {
    const selectedData =
      dataGridRef?.current?.instance?.getSelectedRowsData?.() || [];
    const dataRequest = {
      ctOrderDt: dateFormatStr(new Date()),
      deliverDueDate: dateFormatStr(formData?.orderDueDate),
      ctOrderDetRequests: selectedData.map(o => ({
        vendorId: o?.vendor_id,
        productId: o?.product_id,
        optionId: o?.option_id,
        quantityPerPack: o?.quantity_per_pack,
        ctOrderQuantity: o?.qty,
        purchaseUnitPrice: o?.purchase_unit_price || 0,
        bottleId: o?.bottle_id,
        bottleUnitPrice: o?.bottle_unit_price || 0,
        containerId: o?.option_container_id,
        containerUnitPrice: o?.container_unit_price || 0,
        taxTp: o?.taxTp,
        taxRate: o?.tax_rate,
        purchaseIncvat: o?.purchase_incvat || false,
        classify: o?.classify || 0,
      })),
    };

    const res: any = await refetchCreate({
      data: dataRequest,
    });
    notification({ res });
    if (res?.data?.status == '201') {
      setDataSource(prev => {
        return prev.map(o => {
          o.qty = 0;
          return o;
        });
      });
      setShowPopupConfirm(false);
    }
  };

  const columns: any = [
    {
      dataField: 'seq_no',
      caption: t('Seq'),
      alignment: 'center',
      width: 80,
      allowEditing: false,
    },
    {
      dataField: 'option_cd',
      caption: t('Option'),
      // maxWidth: 90,
      allowEditing: false,
    },
    {
      dataField: 'quantity_per_pack',
      caption: t('QPP'),
      // width: 90,
      allowEditing: false,
    },
    {
      dataField: 'vendor_nm',
      caption: t('Final Vendor'),
      allowEditing: false,
    },
    {
      dataField: 'receive_dt',
      caption: t('Last Order Date'),
      format: DateFormat,
      dataType: 'date',
      // width: 110,
      allowEditing: false,
    },
    {
      dataField: 'last_purchase_unit_price',
      caption: t('Last Purchase Unit Price'),
      dataType: 'number',
      format: DoubleFormat,
      allowEditing: false,
      // width: 110,
    },
    {
      dataField: 'receive_qt',
      caption: t('Last Purchase Qty'),
      dataType: 'number',
      format: QtyFormat,
      // width: 95,
      allowEditing: false,
    },
    {
      dataField: 'min_order',
      caption: t('MOQ'),
      dataType: 'number',
      format: QtyFormat,
      allowEditing: false,
      // width: 120,
      cellRender: (record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 18,
            }}
          >
            <span>{formatNumber(record?.value, QtyFormat)}</span>
            <Button
              icon="edit"
              stylingMode="text"
              onClick={() =>
                setUpdateMinOrderPopup({
                  isShow: true,
                  data: record?.data,
                })
              }
            />
          </div>
        );
      },
    },
    {
      dataField: 'safety_stock',
      caption: t('Safety Stock'),
      dataType: 'number',
      format: QtyFormat,
      allowEditing: false,
      // width: 120,
      cellRender: (record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 18,
            }}
          >
            <span>{formatNumber(record?.value, QtyFormat)}</span>
            <Button
              icon="edit"
              stylingMode="text"
              onClick={() =>
                setUpdateSafetyStockPopup({
                  isShow: true,
                  data: record?.data,
                })
              }
            />
          </div>
        );
      },
    },
    {
      dataField: 'qty',
      caption: t('Order Qty'),
      dataType: 'number',
      format: QtyFormat,
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
    {
      dataField: 'purchase_unit_price',
      caption: t('Order Unit Price '),
      dataType: 'number',
      format: DoubleFormat,
      allowEditing: true,
      cssClass: 'gridcell-editing',
    },
  ];
  /**
   * on row update
   *
   * @param {*} e
   */
  const onRowUpdating = (e: any) => {
    if (!e?.newData) return;
    const keysEdit = Object.keys(e?.newData);
    if (!keysEdit.includes('qty')) {
      return;
    }
    const newData = { ...e?.oldData, ...e.newData };
    const selectedRowKeys =
      dataGridRef?.current?.instance?.option?.()?.selectedRowKeys || [];
    const idx = selectedRowKeys.findIndex(o => o === newData?.seq_no);

    if (newData?.qty) {
      if (idx === -1) {
        dataGridRef?.current?.instance?.selectRows?.([
          ...selectedRowKeys,
          newData?.seq_no,
        ]);
      }
    } else {
      if (idx !== -1) {
        dataGridRef?.current?.instance?.deselectRows?.(
          selectedRowKeys.splice(idx, 1),
        );
      }
    }
    dataGridRef?.current?.instance?.refresh();
  };

  /**
   * on field data change
   *
   * @param {*} e
   */
  const onFieldDataChanged = e => {
    switch (e?.dataField) {
      case 'orderDueDate':
        getOptionOrder(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <LoadPanel visible={loadingUpdate || loading} />
      <div style={{ marginTop: 10, marginBottom: 10, marginRight: 15 }}>
        <Form
          ref={refForm}
          formData={formData}
          showColonAfterLabel={false}
          labelLocation="left"
          colCount={11}
          onFieldDataChanged={onFieldDataChanged}
          items={[
            {
              itemType: 'group',
              colSpan: 9,
              colCount: 3,
              items: [
                {
                  itemType: 'empty',
                },
                {
                  label: { text: t('Order Date:') },
                  dataField: 'orderDate',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    displayFormat: DateFormat,
                    disabled: true,
                  },
                  isRequired: true,
                },
                {
                  label: { text: t('Deliver Due Date:') },
                  dataField: 'orderDueDate',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    min: new Date(),
                    displayFormat: DateFormat,
                  },
                  isRequired: true,
                },
              ],
            },
            {
              itemType: 'button',
              horizontalAlignment: 'right',
              colSpan: 2,
              buttonOptions: {
                type: 'default',
                text: t('Order Registration'),
                icon: 'cart',
                onClick: () => onValidateOrder(),
              },
            },
          ]}
        />
      </div>
      <div>
        <DataGridFull
          columns={columns}
          ref={dataGridRef}
          dataSource={dataSource}
          isShowZeroNumber={true}
          fixedLeft={0}
          fixedRight={0}
          options={{
            columnAutoWidth: true,
            className: 'popup-product-order-view-bottom',
            keyExpr: 'seq_no',
            filterRow: {
              visible: false,
            },
            filterPanel: {
              visible: false,
            },
            searchPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false,
            },
            editing: {
              mode: 'cell',
              allowUpdating: true,
              selectTextOnEditStart: true,
            },
            selection: {
              mode: 'multiple',
              selectAllMode: 'allPages',
              showCheckBoxesMode: 'onClick',
            },
            onRowUpdating: onRowUpdating,
            onEditorPreparing: function (e) {
              const indexOf = columnEdit2053_2Order.indexOf(e?.dataField);
              if (e?.parentType === 'dataRow' && indexOf !== -1) {
                e.editorOptions.onKeyDown = function (ekey) {
                  if (ekey?.event?.keyCode == 13) {
                    const dataGrid = dataGridRef?.current?.instance;
                    let nextElement: any = null;
                    if (columnEdit2053_2Order.length - 1 === indexOf) {
                      nextElement = dataGrid?.getCellElement(
                        e?.row?.rowIndex + 1,
                        columnEdit2053_2Order[0],
                      );
                    } else {
                      nextElement = dataGrid?.getCellElement(
                        e?.row?.rowIndex,
                        columnEdit2053_2Order[indexOf + 1],
                      );
                    }
                    if (nextElement) {
                      ekey?.event?.stopPropagation();
                      dataGrid?.focus(nextElement);
                      nextElement?.click();
                    }
                  }
                };
              }
            },
            onToolbarPreparing: (e: any) => {},
          }}
        />
        {showPopupConfirm && (
          <PopupConfirm
            visible={showPopupConfirm}
            content={t('Are you sure to order?')}
            onOk={onOrder}
            onHiding={() => setShowPopupConfirm(false)}
          />
        )}
        {updateMinOrderPopup?.isShow && (
          <ChangeProductMinOrder
            data={updateMinOrderPopup?.data || {}}
            qtyFormat={QtyFormat}
            onOk={data => {
              if (data === undefined || data == null) return;
              setDataSource(prev => {
                return prev.map(o => {
                  o.min_order = data;
                  return o;
                });
              });
              setUpdateMinOrderPopup({
                isShow: false,
                data: null,
              });
            }}
            onClose={() =>
              setUpdateMinOrderPopup({
                isShow: false,
                data: null,
              })
            }
          />
        )}
        {updateSafetyStockPopup?.isShow && (
          <ChangeProductSafetyStock
            data={updateSafetyStockPopup?.data || {}}
            qtyFormat={QtyFormat}
            onOk={data => {
              if (data === undefined || data == null) return;
              setDataSource(prev => {
                return prev.map(o => {
                  o.safety_stock = data;
                  return o;
                });
              });
              setUpdateSafetyStockPopup({
                isShow: false,
                data: null,
              });
            }}
            onClose={() =>
              setUpdateSafetyStockPopup({
                isShow: false,
                data: null,
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default ViewBottom;
