// Update date: 09-05-2023
// Screen 2087.3

import LoadPanel from 'app/components/LoadPanel';
import useAxios from 'axios-hooks';
import ArrayStore from 'devextreme/data/array_store';
import useDictionary from 'hooks/useDictionary';
import useFormat from 'hooks/useFormat';
import moment from 'moment';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { round } from 'lodash';

const FormSearch = React.lazy(() => import('./FormSearch'));
const List = React.lazy(() => import('./List'));

// Create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'M2087_3_Context';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

const M2087_3 = () => {
  const { t }: any = useDictionary({});
  const [formSearchData, setFormSearchData] = useState<any>({
    dataList: {
      productType: [],
      storeGroup: [],
    },
    productType: '',
    storeGroup: '',
    month: new Date(),
    option: '1',
  });
  const { AmountFormat, DoubleFormat, QtyFormat } = useFormat();
  const [listData, setListData] = useState<any>([]);
  const [{ data, loading: loadingfetchData }, refetchData] = useAxios(
    {},
    { manual: true, autoCancel: true, useCache: false },
  );

  const store: any = new ArrayStore({
    data: listData,
    key: '',
  });
  // Get type day
  const getTypeDay = day => {
    let type = 'other';
    switch (day) {
      case 0:
        type = 'sunday';
        break;
      case 1:
        type = 'monday';
        break;
      case 2:
        type = 'tuesday';
        break;
      case 3:
        type = 'wednesday';
        break;
      case 4:
        type = 'thursday';
        break;
      case 5:
        type = 'friday';
        break;
      case 6:
        type = 'saturday';
        break;
      default:
        break;
    }
    return type;
  };
  // Calcucation value
  const calcucationValue = (data: any, isSum = false) => {
    const bill_count = data?.bill_count || 0;
    const sales_amount = data?.sales_amount || 0;
    const total_amount = data?.total_amount || 0;
    const sales_supply = data?.sales_supply || 0;
    const sales_cost = data?.sales_cost || 0;
    const receive_amount = data?.receive_amount || 0;
    const consign_amount = data?.consign_amount || 0;

    let profit_rate = data?.profit_rate || 0;
    let cost_rate = data?.cost_rate || 0;
    let profit_amount = data?.profit_amount || 0;

    const sales_count_amount =
      bill_count === 0 || total_amount === 0 ? 0 : total_amount / bill_count;
    const date = data?.date || null;
    const average = !!date
      ? round(total_amount / date, AmountFormat.precision)
      : 0;
    // if is sum data, then calculate profit_rate, profit_amount
    // if (isSum) {
    //   profit_rate =
    //     sales_supply === 0
    //       ? 0
    //       : round(100 - (sales_cost / sales_supply) * 100, 2);
    //   profit_amount = round(sales_supply - sales_cost, AmountFormat.precision);
    // }
    return {
      bill_count,
      sales_count_amount,
      sales_amount,
      total_amount,
      sales_supply,
      receive_amount,
      consign_amount,
      profit_rate,
      cost_rate,
      profit_amount,
      average,
      sales_cost,
      date,
    };
  };
  // Sum week
  const convertArrayToWeek = array => {
    if (!array?.length) return null;
    let week: any = {};

    let bill_count = 0;
    let sales_amount = 0;
    let total_amount = 0;
    let sales_supply = 0;
    let sales_cost = 0;
    let receive_amount = 0;
    let consign_amount = 0;
    let date = 0;
    let profit_rate = 0;
    let cost_rate = 0;

    for (let i = 0; i < array?.length; i++) {
      if (array[i].to_char) {
        const day = moment(array[i].to_char).day();
        if (
          moment(array[i].to_char).month() ===
            moment(formSearchData.month).month() ||
          formSearchData?.option === '0'
        ) {
          //convert data to object key rank sun, mon, tue...
          week[getTypeDay(day)] = {
            ...calcucationValue(array[i]),
            type: 'data',
            day: moment(array[i].to_char).format('DD'),
            rank: getTypeDay(day),
            this_month:
              moment(array[i].to_char).month() ===
              moment(formSearchData.month).month(),
          };
          //sum data to calculate the sum in the last column
          bill_count += array[i].bill_count || 0;
          sales_amount += array[i].sales_amount || 0;
          total_amount += array[i].total_amount || 0;
          sales_supply += array[i].sales_supply || 0;
          sales_cost += array[i].sales_cost || 0;
          receive_amount += array[i].receive_amount || 0;
          consign_amount += array[i].consign_amount || 0;
          profit_rate += array[i].profit_rate || 0;
          cost_rate += array[i].cost_rate || 0;
          if (!!array[i].sales_amount) {
            date++;
          }
        }
      }
    }

    //insert sum to view sum column
    const sum = {
      ...calcucationValue(
        {
          bill_count,
          sales_amount,
          total_amount,
          sales_supply,
          receive_amount,
          consign_amount,
          sales_cost,
          date,
          profit_rate,
          cost_rate,
        },
        true,
      ),
      day: '',
      this_month: true,
    };
    if (!Object.values(week)?.length) return {};
    return { ...week, sum: sum };
  };

  useEffect(() => {
    if (!loadingfetchData && data?.data) {
      try {
        let dataList: any = [];
        const data1 = convertArrayToWeek(data?.data?.slice(0, 7));
        const data2 = convertArrayToWeek(data?.data?.slice(7, 14));
        const data3 = convertArrayToWeek(data?.data?.slice(14, 21));
        const data4 = convertArrayToWeek(data?.data?.slice(21, 28));
        const data5 = convertArrayToWeek(data?.data?.slice(28, 35));
        const data6 = convertArrayToWeek(data?.data?.slice(35, 42));

        if (data1) {
          dataList.push(data1);
        }
        if (data2) {
          dataList.push(data2);
        }
        if (data3) {
          dataList.push(data3);
        }
        if (data4) {
          dataList.push(data4);
        }
        if (data5) {
          dataList.push(data5);
        }
        if (data6) {
          dataList.push(data6);
        }

        //calcucation sum last row
        let sumObject: any = {};

        const dataInMonth = data?.data?.filter(
          o =>
            moment(o?.to_char).month() ===
              moment(formSearchData.month).month() ||
            formSearchData?.option === '0',
        );

        dataInMonth?.forEach(o => {
          const day = moment(o.to_char).day();
          //when rank exist ---> sum data vertical
          if (sumObject[getTypeDay(day)]) {
            let date = sumObject[getTypeDay(day)].date;
            if (!!o.sales_amount) {
              date++;
            }
            sumObject[getTypeDay(day)] = {
              ...calcucationValue(
                {
                  bill_count:
                    sumObject[getTypeDay(day)].bill_count + o.bill_count || 0,
                  sales_amount:
                    sumObject[getTypeDay(day)].sales_amount + o.sales_amount ||
                    0,
                  total_amount:
                    sumObject[getTypeDay(day)].total_amount + o.total_amount ||
                    0,
                  sales_supply:
                    sumObject[getTypeDay(day)].sales_supply + o.sales_supply ||
                    0,
                  receive_amount:
                    sumObject[getTypeDay(day)].receive_amount +
                      o.receive_amount || 0,
                  consign_amount:
                    sumObject[getTypeDay(day)].consign_amount +
                      o.consign_amount || 0,
                  sales_cost:
                    sumObject[getTypeDay(day)].sales_cost + o.sales_cost || 0,
                  profit_rate:
                    sumObject[getTypeDay(day)].profit_rate + o.profit_rate || 0,
                  cost_rate:
                    sumObject[getTypeDay(day)].cost_rate + o.cost_rate || 0,
                  date: date,
                },
                true,
              ),
              day: '',
              type: 'sum',
              rank: getTypeDay(day),
              this_month: true,
            };
          } else {
            sumObject[getTypeDay(day)] = {
              ...calcucationValue({ ...o, date: !!o.sales_amount ? 1 : 0 }),
              rank: getTypeDay(day),
              this_month: true,
            };
          }
        });

        let bill_count = 0;
        let sales_amount = 0;
        let total_amount = 0;
        let sales_supply = 0;
        let sales_cost = 0;
        let receive_amount = 0;
        let consign_amount = 0;
        let date = 0;
        let profit_rate = 0;
        let cost_rate = 0;

        // sum total
        const listSum = Object.values(sumObject) || [];
        listSum.forEach((o: any) => {
          bill_count += o.bill_count;
          sales_amount += o.sales_amount;
          total_amount += o.total_amount;
          sales_supply += o.sales_supply;
          sales_cost += o.sales_cost;
          receive_amount += o.receive_amount;
          consign_amount += o.consign_amount;
          date += o.date;
          profit_rate += o.profit_rate;
          cost_rate += o.cost_rate;
        });

        sumObject = {
          ...sumObject,
          sum: {
            ...calcucationValue(
              {
                bill_count,
                sales_amount,
                total_amount,
                sales_supply,
                receive_amount,
                consign_amount,
                sales_cost,
                date,
                profit_rate,
                cost_rate,
              },
              true,
            ),
            type: 'sum',
            day: '',
            this_month: true,
          },
        };
        dataList.push(sumObject);
        setListData(dataList);
      } catch (error) {
        console.log(error);
      }
    }
  }, [data, loadingfetchData]);

  const value: any = {
    t,
    store,
    refetchData,
    formSearchData,
    setFormSearchData,
    AmountFormat,
    DoubleFormat,
    QtyFormat,
  };

  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <LoadPanel visible={loadingfetchData} />
        <FormSearch />
        <List />
      </React.Suspense>
    </ModuleContext.Provider>
  );
};

export default M2087_3;
