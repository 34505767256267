import { ROLE } from './user';

export const router = {
  login: '/login',
  sign_up: '/signup',
  system_management: '/system-management',
  register_program: '/register-program',
  user_info: '/user-info',
  user_register: '/user-register',
  center_operator: '/center-operator',
  not_found_page: '/404',
  demo: '/demo',
  book_mark: '/book-mark',
  history: '/history',
  search: '/search',
  home: '/',
  detail_product_registration: '/product-registration',
  create_product_registration: '/product-registration/create',
  notification: '/notification',
  m2081_R: '/208/2081/2081.R',
};

export const prefixWebviewRouter = '/webview/';
export const routerWebview = {
  m2081_R_webview: `${prefixWebviewRouter}2081.R`,
};

export const FIX_MENU_SIDEBAR = [
  {
    to: '/search',
    icon: 'icon-search1',
    nameMenu: 'Search',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: '/history',
    icon: 'icon-search1',
    nameMenu: 'History',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: '/book-mark',
    icon: 'icon-search1',
    nameMenu: 'Bookmark',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
];

export const MENU_AUTH = [
  {
    to: router.register_program,
    icon: 'icon-code1',
    nameMenu: 'Register Program',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: router.system_management,
    icon: 'icon-system',
    nameMenu: 'System Management',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: router.center_operator,
    icon: 'icon-program',
    nameMenu: 'Center Operator',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: '/transaction',
    icon: 'icon-transction',
    nameMenu: 'Transction',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: '/repair',
    icon: 'icon-repair',
    nameMenu: 'Repair',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
  {
    to: '/setting',
    icon: 'icon-setting',
    nameMenu: 'Setting',
    role: [ROLE.admin, ROLE.customer, ROLE.partner, ROLE.seller],
  },
];

export const NOT_AUTH_SIDEBAR = [
  {
    to: router.sign_up,
    icon: 'icon-Profile',
    nameMenu: 'Sign Up',
  },
  {
    to: router.login,
    icon: 'icon-padlock',
    nameMenu: 'Login',
  },
];

export const HEADER_MENU_LEFT = [
  {
    link: '/common/system-management/user-register',
    icon: 'icon-common',
    text: '공통',
    colorIcon: '#00BFDF',
    colorText: '#00a0bb',
  },
  {
    link: '/common/delivery/delivery-register',
    icon: 'icon-goods',
    text: '상품',
    colorIcon: '#8DC63F',
    colorText: '#66952e',
  },
  {
    link: '/3',
    icon: 'icon-order',
    text: '발주',
    colorIcon: '#D2AC2B',
    colorText: '#9b811c',
  },
  {
    link: '/4',
    icon: 'icon-delivery',
    text: '납품',
    colorIcon: '#F7941D',
    colorText: '#b76e11',
  },
  {
    link: '/5',
    icon: 'icon-logistics',
    text: '물류',
    colorIcon: '#F49AC1',
    colorText: '#966b92',
  },
  {
    link: '/6',
    icon: 'icon-inventory',
    text: '재고',
    colorIcon: '#C78FBF',
    colorText: '#676291',
  },
  {
    link: '/7',
    icon: 'icon-direct',
    text: '직배',
    colorIcon: '#8781BD',
    colorText: '##7d98bb',
  },
  {
    link: '/8',
    icon: 'icon-pos',
    text: 'POS',
    colorIcon: '#95B6DF',
    colorText: '#6d6e71',
  },
  {
    link: '/9',
    icon: 'icon-operation',
    text: '센터운영',
    colorIcon: '#BCBEC0',
    colorText: '#939598',
  },
];

export const HEADER_MENU_PROFILE = [
  { link: '/1', text: 'Link 1' },
  { link: '/2', text: 'Link 2' },
  { link: '/3', text: 'Link 3' },
];
